/* You can add global styles to this file, and also import other style files */
@keyframes bck {
	from{background-position: 0 0;}
	to{background-position: 100% 0}
}
@-webkit-keyframes bck{
	from{background-position: 0 0;}
	to{background-position: 100% 0}

}
html
{
	font-family: Rockwell;
	background-image: url("assets/img/bkg1.jpeg");
	background-size: 200px 200px;
	background-position: center;
	background-repeat: repeat;
	background-attachment: fixed;
	animation-name: bck 15s linear infinite;
	-webkit-animation:bck 15s linear infinite ;
	-webkit-animation-fill-mode:forwards;
	animation-fill-mode: forwards;
	
}	